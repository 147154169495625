import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Seo from "../../components/Seo/Seo"
import ArticleListing from "../../components/ArticleListing"
import "../index.sass"
import PageTransition from "../../components/PageTransition";
import PageHeader from "../../components/PageHeader"

const BlogPage = (props) => {

	const { data } = props
	const posts = data.allMarkdownRemark.edges

	return (

		<PageTransition {...props}>

			<Seo title="Blog" description='Find out about whats going on with the Deviant Robot project.' pathname="/blog" />
			<PageHeader title="Blog" description="Find out about whats going on with the project." />

			<div className="homepage">
				<ArticleListing posts={posts} />
			</div>

		</PageTransition>

	)

}

BlogPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default BlogPage

export const pageQuery = graphql`

	query {

		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Blog"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___articlePublicationDate]
			}

		) {
		edges {
				...indexListing
			}
		}
	}

`